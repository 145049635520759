import React from 'react';
import { useCompletionResultContext } from '../../../context/CompletionResultProvider';
import ContributingTable from '../../formatters/jsonToTable/ContributingTable';
import IcamToolsBaseComponent from './Base/IcamToolsBaseComponent';

const Contributing: React.FC = () => {
  const contextKey = 'contributing';
  const { completionResults } = useCompletionResultContext();

  let result = completionResults[contextKey];
  if (typeof result === 'object') {
    result = JSON.stringify(completionResults[contextKey]);
  }

  return (
    <IcamToolsBaseComponent
      description="Analyses the incident and identifies contributing factors based on the event debrief, witness statements, and other provided documents. Select Files and press submit."
      toolName="contributing-factors-analysis"
      contextKey={contextKey}
    >
      {result && <ContributingTable text={result} />}
    </IcamToolsBaseComponent>
  );
};

export default Contributing;
