import { Logout as LogoutIcon, Menu as MenuIcon } from '@mui/icons-material';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import PrivateRoute from './components/Pages/PrivateRoute';
import EventDetailed from './components/Pages/Tools/EventDetailed';
import SectionMenu from './components/Sections/Menu';
import UploadPanel from './components/Sections/UploadPanel';
import { useAuthContext } from './context/AuthProvider';

// Import your new pages
import CaseRouteHandler from './CaseRouteHandler';
import CaseCreate from './components/Pages/CaseCreate';
import CaseList from './components/Pages/CaseList';
import Home from './components/Pages/Home';
import LandingPage from './components/Pages/LandingPage';
import LoginForm from './components/Pages/LoginForm';
import Contributing from './components/Pages/Tools/Contributing';
import Discrepancies from './components/Pages/Tools/Discrepancies';
import Event from './components/Pages/Tools/Event';
import Icam from './components/Pages/Tools/Icam';
import Interview from './components/Pages/Tools/Interview';
import Learnings from './components/Pages/Tools/Learnings';
import Peepo from './components/Pages/Tools/Peepo';
import Rca from './components/Pages/Tools/Rca';
import Timeline from './components/Pages/Tools/Timeline';
import { useCompletionResultContext } from './context/CompletionResultProvider';
import { useFileContext } from './context/FileProvider';
// ... other imports

const drawerWidth = 240;
const rightPanelWidth = 300;

const App: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const { logout } = useAuthContext();
  const location = useLocation();
  const isStandalonePage =
    location.pathname === '/' ||
    location.pathname === '/login' ||
    location.pathname === '/cases' ||
    location.pathname === '/case-create';
  const navigate = useNavigate();
  const { setFiles } = useFileContext();
  const { resetCompletionResults } = useCompletionResultContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuItemClick = () => {
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const handleMenuOpen = (event: any) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleNavigateToIncidents = () => {
    setFiles([]);
    resetCompletionResults();
    navigate('/'); // Navigate to Incidents or Home
    handleMenuClose();
  };

  const handleLogout = () => {
    setFiles([]); // Clear selected files
    resetCompletionResults(); // Clear completion results
    logout();
    handleMenuClose();
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      {!isStandalonePage && (
        <>
          {/* AppBar for Mobile */}
          {isMobile && (
            <AppBar
              position="fixed"
              sx={{
                backgroundColor: 'white',
                color: 'black',
                boxShadow: 'none',
                borderBottom: '1px solid #e0e0e0',
                height: '60px',
                justifyContent: 'center',
              }}
            >
              <Toolbar
                sx={{
                  minHeight: '60px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{
                    color: 'black',
                    transform: 'scale(1.2)',
                    padding: 2,
                  }}
                >
                  <MenuIcon fontSize="inherit" />
                </IconButton>
                <Typography
                  sx={{
                    color: 'grey',
                    fontSize: '18px',
                    fontFamily: 'Inter, Arial, sans-serif',
                  }}
                >
                  Incident AI
                </Typography>
                {/* Logout Icon */}
                <IconButton
                  color="inherit"
                  aria-label="logout"
                  onClick={handleMenuOpen}
                  sx={{
                    color: 'gray',
                    transform: 'scale(0.9)',
                  }}
                >
                  <LogoutIcon fontSize="inherit" />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleMenuClose}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                  <MenuItem onClick={handleNavigateToIncidents}>
                    Back to Incidents
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Toolbar>
            </AppBar>
          )}

          {/* Left Drawer (Menu) */}
          <Drawer
            variant={isMobile ? 'temporary' : 'permanent'}
            open={isMobile ? mobileOpen : true}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better performance on mobile.
            }}
            sx={{
              width: isMobile ? 0 : drawerWidth, // Hide drawer width on mobile when closed
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                position: 'relative',
              },
            }}
          >
            {/* Close the drawer when a menu item is clicked */}
            <SectionMenu onMenuItemClick={handleMenuItemClick} />
          </Drawer>
        </>
      )}

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          marginTop: isMobile && !isStandalonePage ? '60px' : 0, // Adjust for AppBar height
          position: 'relative',
        }}
      >
        {!isStandalonePage && isMobile && (
          <Box>
            <UploadPanel />
          </Box>
        )}
        <Routes>
          {/* Landing Page */}
          <Route path="/" element={<LandingPage />} />
          {/* Login Form */}
          <Route path="/login" element={<LoginForm />} />
          {/* Case management */}
          <Route path="/cases" element={<CaseList />} />
          <Route path="/case-create" element={<CaseCreate />} />
          {/* Home */}
          <Route
            path="/home/:caseId?"
            element={
              <PrivateRoute>
                <CaseRouteHandler>
                  <Home />
                </CaseRouteHandler>
              </PrivateRoute>
            }
          />
          {/* Event Description */}
          <Route
            path="/event/:caseId?"
            element={
              <PrivateRoute>
                <CaseRouteHandler>
                  <Event />
                </CaseRouteHandler>
              </PrivateRoute>
            }
          />
          {/* Discrepancy Analysis */}
          <Route
            path="/discrepancies/:caseId?"
            element={
              <PrivateRoute>
                <CaseRouteHandler>
                  <Discrepancies />
                </CaseRouteHandler>
              </PrivateRoute>
            }
          />
          {/* Follow-up Interview */}
          <Route
            path="/interview/:caseId?"
            element={
              <PrivateRoute>
                <CaseRouteHandler>
                  <Interview />
                </CaseRouteHandler>
              </PrivateRoute>
            }
          />
          {/* PEEPO Builder */}
          <Route
            path="/peepo/:caseId?"
            element={
              <PrivateRoute>
                <CaseRouteHandler>
                  <Peepo />
                </CaseRouteHandler>
              </PrivateRoute>
            }
          />
          {/* Timeline Builder */}
          <Route
            path="/timeline/:caseId?"
            element={
              <PrivateRoute>
                <CaseRouteHandler>
                  <Timeline />
                </CaseRouteHandler>
              </PrivateRoute>
            }
          />
          {/* ICAM Table */}
          <Route
            path="/icam/:caseId?"
            element={
              <PrivateRoute>
                <CaseRouteHandler>
                  <Icam />
                </CaseRouteHandler>
              </PrivateRoute>
            }
          />
          {/* Contributing Factors */}
          <Route
            path="/contributing/:caseId?"
            element={
              <PrivateRoute>
                <CaseRouteHandler>
                  <Contributing />
                </CaseRouteHandler>
              </PrivateRoute>
            }
          />
          {/* Root Cause Analysis */}
          <Route
            path="/rca/:caseId?"
            element={
              <PrivateRoute>
                <CaseRouteHandler>
                  <Rca />
                </CaseRouteHandler>
              </PrivateRoute>
            }
          />
          {/* Organisational Learnings */}
          <Route
            path="/learnings/:caseId?"
            element={
              <PrivateRoute>
                <CaseRouteHandler>
                  <Learnings />
                </CaseRouteHandler>
              </PrivateRoute>
            }
          />
          {/* Event Detailed Description */}
          <Route
            path="/event-detailed/:caseId?"
            element={
              <PrivateRoute>
                <CaseRouteHandler>
                  <EventDetailed />
                </CaseRouteHandler>
              </PrivateRoute>
            }
          />
        </Routes>
      </Box>

      {/* Right Drawer (UploadPanel) */}
      {!isStandalonePage && !isMobile && (
        <Drawer
          variant="permanent"
          anchor="right"
          sx={{
            width: rightPanelWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: rightPanelWidth,
              boxSizing: 'border-box',
            },
          }}
        >
          <UploadPanel />
        </Drawer>
      )}
    </Box>
  );
};

export default App;
