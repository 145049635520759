import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import MenuItemLayout from './MenuItemLayout';

const Home: React.FC = () => {
  const content = (
    <Box sx={{ padding: '32px' }}>
      <Typography
        sx={{
          fontSize: '16px',
          color: '#666666',
          textAlign: 'center',
          marginTop: 4,
        }}
        data-testid="description"
      >
        Pick your tool, select the documents/files relevant to the incident and
        submit.
        <br />
        <br />
        Incident AI supports text, pdf, document, audio and video file types.
      </Typography>
      <Container
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          width: '670px',
          marginTop: 16,
        }}
      >
        <Box
          component="img"
          src="/icam-workflow.png"
          alt="ICAM Workflow"
          sx={{
            width: '100%',
            maxWidth: '100%',
            objectFit: 'contain',
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
        />
      </Container>
    </Box>
  );

  // TODO Cem: use feature flag to decide this, not the case id
  // return caseId ? <MenuItemLayout>{content}</MenuItemLayout> : content;
  return <MenuItemLayout>{content}</MenuItemLayout>;
};

export default Home;
