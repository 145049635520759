import React from 'react';
import { AuthProvider } from './AuthProvider';
import { CaseProvider } from './CaseProvider';
import { CompletionResultProvider } from './CompletionResultProvider';
import { FileProvider } from './FileProvider';
import { LoadingProvider } from './LoadingProvider';
import { SettingsProvider } from './SettingsProvider';

const Providers: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <AuthProvider>
      <FileProvider>
        <CompletionResultProvider>
          <SettingsProvider>
            <CaseProvider>
              <LoadingProvider>{children}</LoadingProvider>
            </CaseProvider>
          </SettingsProvider>
        </CompletionResultProvider>
      </FileProvider>
    </AuthProvider>
  );
};

export { Providers };
