import React from 'react';
import { useCompletionResultContext } from '../../../context/CompletionResultProvider';
import TimelineTable from './../../../components/formatters/jsonToTable/TimelineTable';
import IcamToolsBaseComponent from './Base/IcamToolsBaseComponent';

const Timeline: React.FC = () => {
  const contextKey = 'timeline';
  const { completionResults } = useCompletionResultContext();

  let result = completionResults[contextKey];
  if (typeof result === 'object') {
    result = JSON.stringify(completionResults[contextKey]);
  }

  return (
    <IcamToolsBaseComponent
      description="Generates the timeline of the incident. Select Files and press submit."
      toolName="timeline-builder"
      contextKey={contextKey}
    >
      {result && <TimelineTable text={result}></TimelineTable>}
    </IcamToolsBaseComponent>
  );
};

export default Timeline;
