import { Stack } from '@mui/material';
import React from 'react';
import { useCompletionResultContext } from '../../../context/CompletionResultProvider';
import InterviewTable from '../../formatters/jsonToTable/InterviewTable';
import IcamToolsBaseComponent from './Base/IcamToolsBaseComponent';

const Interview: React.FC = () => {
  const contextKey = 'interview';
  const { completionResults } = useCompletionResultContext();

  let result = completionResults[contextKey];
  if (typeof result === 'object') {
    result = JSON.stringify(completionResults[contextKey]);
  }

  return (
    <IcamToolsBaseComponent
      description="Generates follow-up interview questions based on the event debrief, witness statements, and other provided documents. Select Files and press submit."
      toolName="generate-follow-up-interview-questions"
      contextKey={contextKey}
    >
      <Stack>
        <InterviewTable text={result} />
      </Stack>
    </IcamToolsBaseComponent>
  );
};

export default Interview;
