import LinkedInIcon from '@mui/icons-material/LinkedIn'; // LinkedIn icon
import MenuIcon from '@mui/icons-material/Menu'; // Burger icon
import LoginIcon from '@mui/icons-material/Person';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { callGetFeatureFlagsApi } from '../../services/api';

interface IcamCardProps {
  imageUrl: string;
  title: string;
  description: string;
  height: number;
}

const LandingPage: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile view
  const isMenuOpen = Boolean(anchorEl);
  const [isCaseFeatureEnabled, setIsCaseFeatureEnabled]=useState<boolean>(true);
  const [caseFeatureFlag] = useState<string>('CASE_FEATURE');

  // Redirect if already logged in
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const getFeatureFlag = async () => {
        try {
          const response = await callGetFeatureFlagsApi(caseFeatureFlag);
          setIsCaseFeatureEnabled(response.data.active as boolean)
        } catch (error) {
          console.log('Error getting feature flags: ', error);
          throw error;
        }
      };
      getFeatureFlag();
      if (isCaseFeatureEnabled) {
        navigate('/cases');
      } else {
        navigate('/home');
      }
    }
  }, [navigate,caseFeatureFlag, isCaseFeatureEnabled]);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLoginClick = () => {
    navigate('/login');
    handleMenuClose();
  };

  const handleFreeTrialClick = () => {
    window.location.href = 'mailto:contact@mineguard-ai.com';
  };

  return (
    <>
      <Box sx={{ padding: '50px', position: 'relative' }}>
        {/* Conditionally render burger menu for mobile and login button for larger screens */}
        {isMobile ? (
          <IconButton
            aria-label="menu"
            aria-controls="login-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
            sx={{
              position: 'absolute',
              top: '-8px',
              right: '24px',
            }}
          >
            <MenuIcon sx={{ fontSize: 32 }} /> {/* Burger menu icon */}
          </IconButton>
        ) : (
          <Button
            variant="contained"
            onClick={handleLoginClick}
            sx={{
              fontWeight: 'bold',
              position: 'absolute',
              top: '50px',
              right: '50px',
              color: 'rgb(123, 31, 162)',
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: '#F2F2F2',
              },
            }}
          >
            Login
          </Button>
        )}

        {/* Mobile menu for login */}
        <Menu
          id="login-menu"
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'menu-button',
          }}
          PaperProps={{
            style: {
              width: '150px',
            },
          }}
        >
          <MenuItem onClick={handleLoginClick}>
            <LoginIcon sx={{ marginRight: 1, color: 'rgb(123, 31, 162)' }} />{' '}
            {/* Icon color */}
            <Typography sx={{ color: 'rgb(123, 31, 162)' }}>
              Login
            </Typography>{' '}
            {/* Text color */}
          </MenuItem>
        </Menu>

        <Grid container spacing={4}>
          <Grid item xs={12} textAlign="center">
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item>
                <img
                  src="/icam_expert_logo.svg"
                  alt="Incident AI Logo"
                  style={{ height: 50 }}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  sx={{
                    marginLeft: 1,
                    fontFamily: 'Inter, Arial, sans-serif',
                  }}
                >
                  Incident AI
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ marginY: 2 }} />
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ fontSize: 16 }}
            >
              Lead Investigator AI Assistant
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="body1" component="p">
              A sophisticated software tool designed to support professionals in
              investigating and analysing mining incidents, helping to prevent
              future occurrences.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 8 }}>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} md={3}>
                <IcamCard
                  imageUrl="/incident-icon.svg"
                  title="Incident"
                  description="Incident requires data collection"
                  height={250}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <IcamCard
                  imageUrl="/evidence-icon.svg"
                  title="Evidence Collection"
                  description="Incident AI helps with evidence collection and PEEPO generation"
                  height={250}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <IcamCard
                  imageUrl="/icam-icon.svg"
                  title="ICAM Analysis"
                  description="Built-in tools help you work through step by step utilizing the latest generative AI to draft your ICAM analysis"
                  height={250}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <IcamCard
                  imageUrl="/report-icon.svg"
                  title="Review and Report"
                  description="Incident AI's outputs are easy to copy and paste to your ICAM report template to edit and finalize"
                  height={250}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 4,
        }}
      >
        <Button
          variant="contained"
          onClick={handleFreeTrialClick}
          sx={{
            mt: 2,
            mb: 6,
            backgroundColor: 'rgb(123, 31, 162)',
            '&:hover': {
              backgroundColor: 'rgb(156, 39, 176)',
            },
            fontSize: '16px',
            textTransform: 'none',
          }}
        >
          Contact us for a Free Trial
        </Button>
      </Box>
      {/* Add the Footer component here */}
      <Footer />
    </>
  );
};

const IcamCard: React.FC<IcamCardProps> = ({
  imageUrl,
  title,
  description,
  height,
}) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: height,
      }}
    >
      <CardMedia
        component="img"
        image={imageUrl}
        alt={title}
        sx={{
          height: '100px',
          objectFit: 'contain',
        }}
      />
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

/* Footer Component */
/* Footer Component */

interface IcamCardProps {
  imageUrl: string;
  title: string;
  description: string;
  height: number;
}

/* Updated Footer Component */
const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'rgb(245, 245, 245)',
        padding: theme => theme.spacing(3),
        mt: 8,
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        {/* About Section */}
        <Grid item xs={12} md={4} textAlign="center">
          <Typography
            sx={{ fontSize: '18px', fontWeight: 'bold' }}
            gutterBottom
          >
            About MineGuard AI
          </Typography>
          <Typography variant="body2">
            Advancing mine safety and enhancing productivity through AI-driven
            solutions.
          </Typography>
          {/* Website Link */}
          <Typography variant="body2" sx={{ mt: 1 }}>
            Visit our website:{' '}
            <Link
              href="https://mineguard-ai.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              mineguard-ai.com
            </Link>
          </Typography>
        </Grid>

        {/* Contact Section */}
        <Grid item xs={12} md={4} textAlign="center">
          <Typography
            sx={{ fontSize: '18px', fontWeight: 'bold' }}
            gutterBottom
          >
            Contact Us
          </Typography>
          <Typography variant="body2">
            Email:{' '}
            <Link href="mailto:contact@mineguard-ai.com">
              contact@mineguard-ai.com
            </Link>
          </Typography>
        </Grid>

        {/* Connect Section */}
        <Grid item xs={12} md={4} textAlign="center">
          <Typography
            sx={{ fontSize: '18px', fontWeight: 'bold' }}
            gutterBottom
          >
            Follow Us
          </Typography>
          <Box>
            <Link
              href="https://www.linkedin.com/company/mineguard-ai"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: 'none', mx: 1 }}
            >
              <LinkedInIcon fontSize="large" sx={{ color: '#0077B5' }} />
            </Link>
            {/* Add other social media icons if needed */}
          </Box>
        </Grid>
      </Grid>

      {/* Added a Divider and Centered Copyright */}
      <Divider sx={{ my: 3 }} />
      <Typography variant="body2" color="textSecondary" align="center">
        © {new Date().getFullYear()} MineGuard AI. All rights reserved.
      </Typography>
    </Box>
  );
};

export default LandingPage;
