import { Logout as LogoutIcon } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useAuthContext } from '../../context/AuthProvider';
import { useCompletionResultContext } from '../../context/CompletionResultProvider';
import { useFileContext } from '../../context/FileProvider';

const LogoutButton: React.FC = () => {
  const { logout } = useAuthContext();
  const { setFiles } = useFileContext();
  const { resetCompletionResults } = useCompletionResultContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setFiles([]); // Clear selected files
    resetCompletionResults(); // Clear completion results
    logout();
    handleMenuClose();
  };

  return (
    <>
      <IconButton
        onClick={handleMenuOpen}
        aria-label="logout"
        sx={{ color: 'grey' }}
      >
        <LogoutIcon sx={{ fontSize: '1.5rem' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleLogout}>
          <Typography variant="body1" sx={{ color: 'grey' }}>
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default LogoutButton;
