import { Typography } from '@mui/material';
import React from 'react';
import { useCompletionResultContext } from '../../../context/CompletionResultProvider';
import IcamToolsBaseComponent from './Base/IcamToolsBaseComponent';

const Event: React.FC = () => {
  const contextKey = 'event';
  const { completionResults } = useCompletionResultContext();

  let result = completionResults[contextKey];
  if (typeof result === 'object') {
    result = JSON.stringify(completionResults[contextKey]);
  }

  return (
    <IcamToolsBaseComponent
      description="Generates brief event description based on the event debrief, witness statements, and other provided documents. Select Files and press submit."
      toolName="brief-description-of-the-event"
      contextKey="event"
    >
      {result && (
        <Typography sx={{ marginTop: '32px' }} data-testid="report-result">
          {result}
        </Typography>
      )}
    </IcamToolsBaseComponent>
  );
};

export default Event;
