import {
  Alert,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../context/AuthProvider';
import { useCaseContext } from '../../../../context/CaseProvider';
import { useCompletionResultContext } from '../../../../context/CompletionResultProvider';
import { useFileContext } from '../../../../context/FileProvider';
import { useLoadingContext } from '../../../../context/LoadingProvider';
import { useSettingsContext } from '../../../../context/SettingsProvider';
import { FileToUpload } from '../../../../interfaces/FileToUpload';
import {
  callQueryDocumentsApi,
  callToolCompletionByCaseApi,
  callToolsApi,
} from '../../../../services/api';
import { CompletionTimeMessage } from '../../../Sections/utility/CompletionTimeMessage';
import DarkButton from '../../../common/DarkButton';
import MenuItemLayout from '../../MenuItemLayout';

interface IcamToolsBaseProps {
  description: string;
  toolName: string;
  contextKey: string;
  children: React.ReactNode;
}

const IcamToolsBaseComponent: React.FC<IcamToolsBaseProps> = ({
  description,
  toolName,
  contextKey,
  children,
}) => {
  const { files, totalSize } = useFileContext(); // Access total size from FileContext
  const { settings } = useSettingsContext();
  const { user } = useAuthContext();
  const { setCompletionResult, completionResults } =
    useCompletionResultContext();
  const { loading, setLoading } = useLoadingContext();
  const { caseId } = useCaseContext();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [prompt, setPrompt] = useState<string>('');
  const [isPromptLoading, setIsPromptLoading] = useState<boolean>(true);
  const [createdAt, setCreatedAt] = useState<string | null>(null);
  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);

  useEffect(
    () => {
      const init = async () => {
        try {
          const response = await callToolsApi(toolName);
          const tool = response.data;

          setPrompt(tool?.prompt);

          if (caseId && tool) {
            initCompletion(caseId, tool.id);
          }
        } catch (err) {
          console.log('API error calling Tool: ', err);
          throw err;
        } finally {
          setIsPromptLoading(false);
        }
      };

      const initCompletion = async (caseId: string, toolId: string) => {
        try {
          const response = await callToolCompletionByCaseApi(caseId, toolId);
          setCompletionResult(contextKey, response.data?.result);
          setCreatedAt(response.data?.createdAt);
        } catch (err) {
          console.log('API error calling Tool: ', err);
          throw err;
        } finally {
          setIsPromptLoading(false);
        }
      };

      init();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [toolName, contextKey, caseId]
  );

  const existingResult = completionResults[contextKey] || '';

  const handleSubmit = async () => {
    if (files.length === 0) {
      alert('Please select one or more files.');
      return;
    }

    setErrorMessage('');
    setCompletionResult(contextKey, '');
    setLoading(true);
    setIsSubmitClicked(true);

    try {
      if (user?.role === 'superuser') {
        await queryDocuments(files, prompt);
      } else {
        await queryDocuments(files);
      }
    } finally {
      setLoading(false);
    }
  };

  const queryDocuments = async (
    files: FileToUpload[],
    superuserPrompt?: string
  ) => {
    try {
      let apiEndpoint = `queryDocuments/report?tool=${toolName}&state=${settings.state}&minetype=${settings.mineType}&commodity=${settings.commodity}&language=${settings.language}&llm=${settings.llm}&llmTemperature=${settings.llmTemperature}`;

      const filesFromFileSystem = files
        .filter(file => file.isExisting !== true) // Exclude files from DB
        .map(file => file.actualFile)
        .filter((x): x is File => x !== undefined);

      // Gather only the file names, excluding filesFromFileSystem
      const existingFilesFromDb = files
        .filter(file => file.isExisting === true) // Only files from DB and still active
        .map(file => ({
          name: file.name,
          isRemoved: file.isRemoved,
        }));

      // Include the prompt in the API call, possibly as part of the request body or query params
      const response = await callQueryDocumentsApi(
        apiEndpoint,
        filesFromFileSystem,
        existingFilesFromDb,
        superuserPrompt,
        caseId
      );

      const responseParts = response?.data.split('$$$');

      const answer = responseParts[0];
      const errors = responseParts[1];

      setCompletionResult(contextKey, answer);

      if (errors) {
        setErrorMessage(errors);
      } else {
        setErrorMessage(''); // Clear any existing errors
      }
    } catch (error: any) {
      console.error('Error calling ICAM service:', error);

      if (error.response && error.response.body && error.response.body.error) {
        const apiError = error.response.body.error;
        const message =
          apiError.message ||
          `Calling the service didn't work. Please try again, or contact us via contact@mineguard-ai.com if it persists.`;
        setErrorMessage(message);
      } else {
        setErrorMessage(
          `Calling the service didn't work. Please try again, or contact us via contact@mineguard-ai.com if it persists.`
        );
      }
    }
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        padding: {
          sm: '20px',
          xs: '20px',
          md: '50px',
        },
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography
          sx={{ fontSize: '16px', color: '#666666' }}
          data-testid="description"
        >
          {description}
        </Typography>

        {/* Prompt Text Area for superusers only */}
        {user?.role === 'superuser' && (
          <Box sx={{ marginTop: '20px', position: 'relative' }}>
            <TextField
              label={prompt ? 'Prompt' : undefined}
              multiline
              minRows={10}
              maxRows={20}
              fullWidth
              value={prompt}
              onChange={e => setPrompt(e.target.value)}
              variant="outlined"
              disabled={isPromptLoading}
            />
            {isPromptLoading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: 14,
                  left: 14,
                }}
              />
            )}
          </Box>
        )}

        {/* Submit Button */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '40px',
          }}
        >
          <DarkButton
            onClick={handleSubmit}
            disabled={
              files.length === 0 ||
              (user?.role === 'superuser' && (!prompt || isPromptLoading))
            }
            loading={loading}
            testId="submit-button"
          >
            SUBMIT
          </DarkButton>
        </Box>

        {/* Loading and Completion Time Message */}
        {loading ? (
          <CompletionTimeMessage totalSize={totalSize} files={files} />
        ) : errorMessage ? (
          <Alert
            severity="error"
            sx={{
              mt: 2,
              mb: 0,
              p: '4px 8px',
              fontSize: '0.75rem',
              '& .MuiAlert-icon': {
                fontSize: '1.2rem',
                marginRight: '8px',
              },
            }}
          >
            {errorMessage.split(';').map((msg, index) => (
              <React.Fragment key={index}>
                {msg.trim()}
                <br />
                <br />
              </React.Fragment>
            ))}
          </Alert>
        ) : null}

        {caseId && existingResult && !isSubmitClicked && (
          <Box sx={{ color: 'darkgrey', marginTop: '32px' }}>
            <Typography sx={{ fontSize: '0.9em' }}>
              Loaded from previous session at{' '}
              {new Date(createdAt ?? '').toLocaleDateString('en-GB')}{' '}
              {new Date(createdAt ?? '').toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Typography>
            <hr />
          </Box>
        )}

        {/* Children Components */}
        <Box sx={{ marginTop: '16px' }}>{children}</Box>
      </Box>
      {/* Note always at the bottom */}
      <Typography
        sx={{
          fontSize: '14px',
          color: '#666666',
          marginTop: '48px',
        }}
      >
        <b>Note:</b> Incident AI Basic is not intended to support very large
        documents such as OEM manuals or Mining Acts. Upgrade to Advanced
        version if required.
      </Typography>
    </Box>
  );

  // TODO Cem: use feature flag to decide this, not the case id
  // return caseId ? <MenuItemLayout>{content}</MenuItemLayout> : content;
  return <MenuItemLayout>{content}</MenuItemLayout>;
};

export default IcamToolsBaseComponent;
