import AddIcon from '@mui/icons-material/Add';
import GroupIcon from '@mui/icons-material/Group';
import LockIcon from '@mui/icons-material/Lock';
import PublicIcon from '@mui/icons-material/Public';
import {
  Box,
  Button,
  Container,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { callCreateCaseApi } from '../../services/api';

const CreateCase: React.FC = () => {
  const [title, setTitle] = useState<string>('');
  const [visibility, setVisibility] = useState<string>('my-company');
  const [titleError, setTitleError] = useState<string>('');
  const [titleCharCount, setTitleCharCount] = useState<number>(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputTitle = event.target.value;
    setTitle(inputTitle);
    setTitleCharCount(inputTitle.length);

    if (inputTitle.length > 80) {
      setTitleError('Title cannot exceed 80 characters');
    } else if (inputTitle.trim() === '') {
      setTitleError('Title cannot be empty');
    } else {
      setTitleError('');
    }
  };

  const handleVisibilityChange = (event: SelectChangeEvent<string>) => {
    setVisibility(event.target.value);
  };

  const handleCreateCase = async () => {
    // Validate before proceeding
    if (title.trim() === '' || title.length > 80) {
      setTitleError('Please enter a valid title');
      return;
    }

    const response = await callCreateCaseApi(title, visibility);

    // Proceed to create the case (e.g., send data to server)
    // For demonstration, navigate back to case list
    // TODO implement the new route
    navigate(`/home/${response.data.id}`);
  };

  return (
    <Container sx={{ py: 4 }}>
      <Fade in timeout={1000}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            px: 2,
            maxWidth: '600px',
            margin: '0 auto',
            height: '100vh',
            justifyContent: 'center',
            position: 'relative',
            top: isMobile ? '-3%' : '-7.5%',
          }}
        >
          {/* Logo and Title */}
          <Box
            sx={{
              position: 'absolute',
              top: 24,
              left: 8,
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
            }}
          >
            <img
              src="/icam_expert_logo.svg"
              alt="Incident AI Logo"
              style={{ height: 50 }}
            />
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              sx={{
                marginLeft: 2,
                marginTop: 1,
                fontFamily: 'Inter, Arial, sans-serif',
              }}
            >
              Incident AI
            </Typography>
          </Box>

          {/* Page Header */}
          <Typography
            component="div"
            sx={{
              fontSize: isMobile ? '24px' : '24px',
              color: 'darkgrey',
              textAlign: 'center',
            }}
          >
            New Incident
          </Typography>

          {/* Title Input */}
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={title}
            onChange={handleTitleChange}
            error={titleError !== ''}
            helperText={
              titleError !== '' ? (
                titleError
              ) : (
                <span
                  style={{
                    color: titleCharCount >= 80 ? 'orange' : 'inherit',
                  }}
                >
                  {`${titleCharCount}/80 characters`}
                </span>
              )
            }
            inputProps={{ maxLength: 80 }}
          />

          {/* Visibility Selection */}
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="visibility-label">Visibility</InputLabel>
            <Select
              labelId="visibility-label"
              value={visibility}
              onChange={handleVisibilityChange}
              label="Visibility"
            >
              <MenuItem value="my-company">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#666666',
                  }}
                >
                  <PublicIcon sx={{ fontSize: '20px', mr: 1 }} />
                  My company
                </Box>
              </MenuItem>
              <MenuItem value="my-group">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#666666',
                  }}
                >
                  <GroupIcon sx={{ fontSize: '20px', mr: 1 }} />
                  My group
                </Box>
              </MenuItem>
              <MenuItem value="only-me">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#666666',
                  }}
                >
                  <LockIcon sx={{ fontSize: '20px', mr: 1 }} />
                  Only me
                </Box>
              </MenuItem>
            </Select>
          </FormControl>

          {/* Create Button */}
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleCreateCase}
            sx={{
              backgroundColor: 'rgb(123, 31, 162)',
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgb(156, 39, 176)',
              },
              alignSelf: 'flex-end',
            }}
          >
            Create
          </Button>
        </Box>
      </Fade>
    </Container>
  );
};

export default CreateCase;
