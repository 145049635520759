import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';

interface IcamTextFieldProps {
  label: string;
  type?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  InputProps?: TextFieldProps['InputProps'];
}

const IcamTextField: React.FC<IcamTextFieldProps> = ({
  label,
  type,
  value = 'text',
  InputProps,
  onChange,
}) => {
  return (
    <TextField
      label={label}
      type={type}
      variant="outlined"
      value={value}
      onChange={onChange}
      InputProps={InputProps}
      sx={{
        mb: 0,
        mt: 0,
        width: '260px',
        '& .MuiOutlinedInput-root': {
          height: '44px',
          padding: '0 12px',
          '& fieldset': {
            borderColor: 'darkblue',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'darkblue',
          },
        },
        '& .MuiInputLabel-root': {
          top: '-4px',
          fontSize: '0.875rem',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: 'darkblue',
        },
        '& .MuiInputBase-input': {
          height: '100%',
          padding: '10px 0',
          boxSizing: 'border-box',
          lineHeight: '1.5',
          fontSize: '0.875rem',
          '&::placeholder': {
            fontSize: '1.125rem',
            color: 'rgba(0, 0, 0, 0.6)',
            opacity: 1,
          },
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0px 1000px white inset',
            WebkitTextFillColor: '#000',
            transition: 'background-color 5000s ease-in-out 0s',
          },
        },
        '& input::placeholder': {
          fontSize: '1.125rem',
          opacity: 1,
          color: 'rgba(0, 0, 0, 0.6)',
        },
      }}
    />
  );
};

export default IcamTextField;
