import { jwtDecode } from 'jwt-decode'; // Corrected the import
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { DecodedToken } from '../interfaces/DecodedToken';

// Interface for the context
interface AuthContextType {
  user: DecodedToken | null;
  setUser: React.Dispatch<React.SetStateAction<DecodedToken | null>>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Custom hook to use the UserContext
export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<DecodedToken | null>(null);
  const navigate = useNavigate();

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken: DecodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000; // Current time in seconds

          if (decodedToken.exp < currentTime) {
            logout(); // Token expired, clear user data and redirect to login
          } else {
            setUser(decodedToken);
          }
        } catch (error) {
          logout(); // If token is invalid, log out
        }
      }
    };

    // Check token expiration on mount
    checkTokenExpiration();

    // Optionally, check token expiration periodically (e.g., every minute)
    const interval = setInterval(() => {
      checkTokenExpiration();
    }, 60 * 1000); // 1 minute

    return () => clearInterval(interval); // Clear interval on unmount
  }, [logout]);

  return (
    <AuthContext.Provider value={{ user, setUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
