import React from 'react';
import { useCompletionResultContext } from '../../../context/CompletionResultProvider';
import DiscrepancySummary from '../../formatters/DiscrepancySummary';
import IcamToolsBaseComponent from './Base/IcamToolsBaseComponent';

const Discrepancies: React.FC = () => {
  const contextKey = 'discrepancies';
  const { completionResults } = useCompletionResultContext();

  let result = completionResults[contextKey];

  return (
    <IcamToolsBaseComponent
      description="Analyses the discrepancies between information from multiple sources, based on the event debrief, witness statements, and any other documents provided. Select Files and press submit."
      toolName="discrepancy-analysis"
      contextKey={contextKey}
    >
      {result && <DiscrepancySummary text={result} />}
    </IcamToolsBaseComponent>
  );
};

export default Discrepancies;
