// src/components/Common/DarkButton.tsx

import { Button, CircularProgress } from '@mui/material';
import React from 'react';

interface LightButtonProps {
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  children: React.ReactNode;
  startIcon?: React.ReactNode;
  testId: string;
}

const LightButton: React.FC<LightButtonProps> = ({
  onClick,
  disabled = false,
  loading = false,
  children,
  startIcon,
  testId,
}) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={disabled || loading}
      startIcon={startIcon ?? null}
      data-testid={testId}
      sx={{
        color: 'rgb(123, 31, 162)',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: '#F2F2F2',
        },
        '&.Mui-disabled': {
          color: 'rgba(255, 255, 255, 1)',
          background: loading ? 'rgb(123, 31, 162)' : 'darkgray',
        },
      }}
    >
      {loading ? (
        <CircularProgress
          size={24}
          sx={{ color: 'rgba(255, 255, 255, 0.8)' }}
          data-testid="loading-spinner"
        />
      ) : (
        children
      )}
    </Button>
  );
};

export default LightButton;
