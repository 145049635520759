import React from 'react';
import { useCompletionResultContext } from '../../../context/CompletionResultProvider';
import LearningsTable from '../../formatters/jsonToTable/LearningsTable';
import IcamToolsBaseComponent from './Base/IcamToolsBaseComponent';

const Learnings: React.FC = () => {
  const contextKey = 'learnings';
  const { completionResults } = useCompletionResultContext();

  let result = completionResults[contextKey];
  if (typeof result === 'object') {
    result = JSON.stringify(completionResults[contextKey]);
  }

  return (
    <IcamToolsBaseComponent
      description="Generates generic and high level key learnings that the organisation can benefit from. Select Files and press submit."
      toolName="key-learnings"
      contextKey={contextKey}
    >
      {result && <LearningsTable text={result} />}
    </IcamToolsBaseComponent>
  );
};

export default Learnings;
