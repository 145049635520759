import {
  Add as AddIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Container,
  Fade,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCaseContext } from '../../context/CaseProvider';
import { Case } from '../../interfaces/Case';
import { callGetCasesApi } from '../../services/api';
import DarkButton from '../common/DarkButton';
import LightButton from '../common/LightButton';
import LogoutButton from '../common/LogoutButton';

const CaseList: React.FC = () => {
  const [cases, setCases] = useState<Case[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { setCaseId } = useCaseContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useEffect(() => {
    const getCases = async () => {
      try {
        const response = await callGetCasesApi();
        setCases(response.data as Case[]);
      } catch (err) {
        console.log('Error getting cases: ', err);
        setError('Error getting cases, please try again later.');
      } finally {
        setLoading(false);
      }
    };

    getCases();
  }, []);

  const handleCreateNewCase = () => {
    navigate('/case-create');
  };

  const handleExplore = () => {
    setCaseId(null);
    navigate('/home');
  };

  const handleCardClick = (id: string) => {
    setCaseId(id);
    navigate(`/home/${id}`);
  };

  // Function to format the date
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Container sx={{ py: 4, position: 'relative', minHeight: '100vh' }}>
      {/* Page Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 4,
          px: 2,
        }}
      >
        {!isMobile && (
          <Typography
            component="div"
            sx={{ marginLeft: '30px', fontSize: '30px', color: 'darkgrey' }}
          >
            Incidents
          </Typography>
        )}
        <Box sx={{ display: 'flex', mr: '32px' }}>
          <LightButton
            onClick={handleExplore}
            loading={loading}
            testId="new-case-button"
          >
            Explore
          </LightButton>
          <Box sx={{ mr: '16px' }}></Box>
          <DarkButton
            onClick={handleCreateNewCase}
            startIcon={<AddIcon />}
            loading={loading}
            testId="new-case-button"
          >
            New Incident
          </DarkButton>
        </Box>
      </Box>

      {/* Loading Indicator */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
          <Typography variant="body1" color="text.secondary">
            Loading cases...
          </Typography>
        </Box>
      ) : error ? (
        // Error Message
        <Fade in timeout={500}>
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        </Fade>
      ) : (
        /* Cases List */
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 3,
            justifyContent: 'center',
            px: 2,
          }}
        >
          {!cases || cases?.length === 0 ? (
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mt: 25, fontSize: '20px', color: '#878787' }}
            >
              No incidents found. Click "New Incident" to add one.
            </Typography>
          ) : (
            cases.map(c => (
              <Fade in timeout={1000} key={c.id}>
                <Card
                  sx={{
                    width: { xs: '100%', sm: '48%', md: '30%' },
                    minWidth: 280,
                    height: '260px', // Adjusted height to accommodate new fields
                    position: 'relative',
                    boxShadow: 3,
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'scale(1.02)',
                    },
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CardActionArea
                    onClick={() => handleCardClick(c.id)}
                    sx={{ flexGrow: 1 }}
                  >
                    <CardContent
                      sx={{
                        paddingTop: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '22px',
                          fontWeight: '500',
                          margin: 0,
                          padding: '16px 16px 8px 16px',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {c.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          margin: 0,
                          padding: '0 16px 16px 16px',
                          flexGrow: 1,
                          display: '-webkit-box',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          lineHeight: '1.4em',
                          overflowY: 'auto',
                          maxHeight: '8em',
                        }}
                      >
                        {c.description?.replace("''", "'")}
                      </Typography>
                    </CardContent>
                    {/* Bottom Section */}
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 16,
                        left: 16,
                        right: 16,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}
                    >
                      {/* File Info and Visibility in One Row */}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          color="text.secondary"
                          variant="body2"
                          sx={{
                            color: '#591675',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            paddingLeft: '16px',
                            borderRadius: '4px',
                          }}
                        >
                          {c.fileCount === 0
                            ? 'No files uploaded'
                            : `${c.fileCount} file${
                                c.fileCount !== 1 ? 's' : ''
                              } of ${
                                c.totalFileSizeMB
                                  ? c.totalFileSizeMB.toFixed(2)
                                  : 0
                              } MB`}
                        </Typography>

                        <Box sx={{ paddingRight: '16px' }}>
                          <Chip
                            icon={
                              <VisibilityIcon
                                sx={{ fontSize: '1rem', color: 'inherit' }}
                              />
                            }
                            label={
                              c.companyId
                                ? 'Company'
                                : c.tenantId
                                ? 'Group'
                                : 'Only me'
                            }
                            variant="outlined"
                            sx={{
                              backgroundColor: 'white',
                              color: '#5870B8',
                              borderColor: '#BDC6E2',
                              borderWidth: 1.1,
                              borderRadius: '4px',
                              boxShadow: 1,
                              '& .MuiChip-icon': { color: '#5870B8' },
                            }}
                          />
                        </Box>
                      </Box>

                      {/* Created Date and Email Full Width Row */}
                      <Typography
                        color="#A9A9A9"
                        sx={{
                          fontSize: '12px',
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          padding: '2px 8px',
                          borderRadius: '4px',
                          width: '100%', // Ensures it takes full width
                          textAlign: 'center',
                        }}
                      >
                        {c['user.email'] || 'Unknown'} -{' '}
                        {formatDate(c.createdAt)}
                      </Typography>
                    </Box>
                  </CardActionArea>
                </Card>
              </Fade>
            ))
          )}
        </Box>
      )}

      {/* Logout button at the bottom right */}
      {!isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 32,
            right: 32,
            zIndex: 1000,
          }}
        >
          <LogoutButton />
        </Box>
      )}
    </Container>
  );
};

export default CaseList;
