import React from 'react';
import { useCompletionResultContext } from '../../../context/CompletionResultProvider';
import PeepoAnalysisTable from '../../formatters/jsonToTable/PeepoTable';
import IcamToolsBaseComponent from './Base/IcamToolsBaseComponent';

const Peepo: React.FC = () => {
  const contextKey = 'peepo';
  const { completionResults } = useCompletionResultContext();

  let result = completionResults[contextKey];
  if (typeof result === 'object') {
    result = JSON.stringify(completionResults[contextKey]);
  }

  return (
    <IcamToolsBaseComponent
      description="Analyses the input and builds a PEEPO for the type of the incident in a table format. Select Files and press submit."
      toolName="peepo-builder"
      contextKey={contextKey}
    >
      {result && <PeepoAnalysisTable text={result} />}
    </IcamToolsBaseComponent>
  );
};

export default Peepo;
