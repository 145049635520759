import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    // Simulate a check to see if the user is authenticated
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token); // Set true if token exists, false otherwise
  }, []);

  if (isAuthenticated === null) {
    // While checking the authentication status, return a loading indicator
    return <>Loading...</>;
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
