import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import React, { forwardRef, useState } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from 'react-router-dom';
import { useCaseContext } from '../../context/CaseProvider';
import { useLoadingContext } from '../../context/LoadingProvider';

// Define a custom link component that forwards refs and passes the `to` prop.
const CustomRouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
  function CustomLink(props, ref) {
    const { to, ...other } = props;
    return <RouterLink ref={ref} to={to} {...other} />;
  }
);

// StyledListItemButton for consistent styling, handling the disabled prop correctly
const StyledListItemButton = styled(
  ({ disabled, ...props }: RouterLinkProps & { disabled?: boolean }) => (
    <ListItemButton
      component={CustomRouterLink}
      disabled={disabled}
      {...props}
    />
  )
)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  position: 'relative', // To position the underline correctly
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  // Style for the disabled state
  '&.Mui-disabled': {
    backgroundColor: theme.palette.action.disabledBackground,
    cursor: 'not-allowed',
    '& .MuiListItemText-primary': {
      color: theme.palette.action.disabled,
    },
  },
}));

// Define a styled component for group headers
const StyledGroupListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '& .MuiTypography-root': {
    fontSize: '14.4px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.action.disabledBackground,
    cursor: 'not-allowed',
    '& .MuiTypography-root': {
      color: theme.palette.action.disabled,
    },
  },
}));

interface MenuProps {
  onMenuItemClick: () => void;
}

const Menu: React.FC<MenuProps> = ({ onMenuItemClick }) => {
  const theme = useTheme();
  const location = useLocation();
  const { loading } = useLoadingContext();
  const { caseId } = useCaseContext();

  const [openEvidenceCollection, setOpenEvidenceCollection] = useState(true);
  const [openICAMAnalysis, setOpenICAMAnalysis] = useState(true);
  const [openEventDescription, setOpenEventDescription] = useState(true);

  const handleClickEvidenceCollection = () => {
    if (!loading) {
      setOpenEvidenceCollection(!openEvidenceCollection);
    }
  };

  const handleClickICAMAnalysis = () => {
    if (!loading) {
      setOpenICAMAnalysis(!openICAMAnalysis);
    }
  };

  const handleClickEventDescription = () => {
    if (!loading) {
      setOpenEventDescription(!openEventDescription);
    }
  };

  const getPath = (basePath: string) => {
    return caseId ? `${basePath}/${caseId}` : basePath;
  };

  const menuItemCss = {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 500,
    color: '#666666',
  };

  const selectedMenuItemCss = {
    ...menuItemCss,
    fontWeight: 'bold' as const,
  };

  const boldMenuItemStyle = {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 'bold' as const,
    color: '#666666',
  };

  const EVIDENCE_COLLECTION_GROUP = 'Evidence Collection';
  const ICAM_ANALYSIS_GROUP = 'ICAM Analysis';
  const EVENT_DESCRIPTION_GROUP = 'Event Description';

  const menuItems = [
    {
      path: getPath('/discrepancies'),
      title: 'Discrepancy Analysis',
      group: EVIDENCE_COLLECTION_GROUP,
    },
    {
      path: getPath('/interview'),
      title: 'Follow-up Interview',
      group: EVIDENCE_COLLECTION_GROUP,
    },
    {
      path: getPath('/peepo'),
      title: 'PEEPO Builder',
      group: EVIDENCE_COLLECTION_GROUP,
    },
    {
      path: getPath('/timeline'),
      title: 'Timeline Builder',
      group: ICAM_ANALYSIS_GROUP,
    },
    { path: getPath('/icam'), title: 'ICAM Table', group: ICAM_ANALYSIS_GROUP },
    {
      path: getPath('/contributing'),
      title: 'Contributing Factors',
      group: ICAM_ANALYSIS_GROUP,
    },
    {
      path: getPath('/rca'),
      title: 'Root Cause Analysis',
      group: ICAM_ANALYSIS_GROUP,
    },
    {
      path: getPath('/learnings'),
      title: 'Organisational Learnings',
      group: ICAM_ANALYSIS_GROUP,
    },
    { path: getPath('/event'), title: 'Brief', group: EVENT_DESCRIPTION_GROUP },
    {
      path: getPath('/event-detailed'),
      title: 'Detailed',
      group: EVENT_DESCRIPTION_GROUP,
    },
  ];

  const isSelected = (path: string) => location.pathname === path;

  const renderMenuItems = (group: string) => {
    return menuItems
      .filter(item => item.group === group)
      .map(item => (
        <StyledListItemButton
          key={item.path}
          to={item.path}
          onClick={onMenuItemClick}
          disabled={loading && !isSelected(item.path)}
          sx={{
            backgroundColor: isSelected(item.path)
              ? loading
                ? 'white'
                : theme.palette.action.hover
              : 'inherit',
            borderBottom: isSelected(item.path)
              ? '1px solid rgb(156, 39, 176)'
              : '1px solid #f0f0f0',
            pl: 4,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            '&::after': {
              content: '""',
              display: isSelected(item.path) ? 'block' : 'none',
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '1px',
              backgroundColor: 'rgb(156, 39, 176)',
            },
          }}
        >
          <ListItemText
            primary={item.title}
            primaryTypographyProps={
              isSelected(item.path) ? selectedMenuItemCss : menuItemCss
            }
          />
        </StyledListItemButton>
      ));
  };

  return (
    <Box
      sx={{
        width: 240,
        boxShadow: theme.shadows[3],
        bgcolor: 'background.paper',
        minHeight: '100vh',
        padding: theme.spacing(2),
        position: 'relative',
      }}
    >
      {/* Logo Section: Always Display the Logo */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, flexShrink: 0 }}>
        <img
          src="/icam_expert_logo.svg"
          alt="Incident AI Logo"
          style={{ width: 56, height: 56, marginRight: theme.spacing(1) }}
        />
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Inter, Arial, sans-serif',
            fontSize: '1.25rem',
            fontWeight: 700,
            color: theme.palette.text.primary,
          }}
        >
          Incident AI
        </Typography>
      </Box>

      <List sx={{ marginTop: '20px' }}>
        {/* Home Menu Item */}
        <StyledListItemButton
          key={getPath('/home')}
          to={getPath('/home')}
          onClick={onMenuItemClick}
          disabled={loading && !isSelected('/home')}
          sx={{
            backgroundColor: isSelected('/home')
              ? loading
                ? 'white'
                : theme.palette.action.hover
              : 'inherit',
            borderBottom: isSelected('/home')
              ? '1px solid rgb(156, 39, 176)'
              : '1px solid #f0f0f0',
            position: 'relative',
            pl: 2,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            '&::after': {
              content: '""',
              display: isSelected('/home') ? 'block' : 'none',
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '1px',
              backgroundColor: 'rgb(156, 39, 176)',
            },
          }}
        >
          <ListItemText
            primary="Home"
            primaryTypographyProps={boldMenuItemStyle}
          />
        </StyledListItemButton>

        {/* Event Description Group */}
        <StyledGroupListItemButton
          onClick={handleClickEventDescription}
          disabled={loading}
        >
          <ListItemText primary="Event Description" />
          {!loading && (openEventDescription ? <ExpandLess /> : <ExpandMore />)}
        </StyledGroupListItemButton>
        <Collapse in={openEventDescription} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {renderMenuItems(EVENT_DESCRIPTION_GROUP)}
          </List>
        </Collapse>

        {/* Evidence Collection Group */}
        <StyledGroupListItemButton
          onClick={handleClickEvidenceCollection}
          disabled={loading}
        >
          <ListItemText primary="Evidence Collection" />
          {!loading &&
            (openEvidenceCollection ? <ExpandLess /> : <ExpandMore />)}
        </StyledGroupListItemButton>
        <Collapse in={openEvidenceCollection} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {renderMenuItems(EVIDENCE_COLLECTION_GROUP)}
          </List>
        </Collapse>

        {/* ICAM Analysis Group */}
        <StyledGroupListItemButton
          onClick={handleClickICAMAnalysis}
          disabled={loading}
        >
          <ListItemText primary="ICAM Analysis" />
          {!loading && (openICAMAnalysis ? <ExpandLess /> : <ExpandMore />)}
        </StyledGroupListItemButton>
        <Collapse in={openICAMAnalysis} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {renderMenuItems(ICAM_ANALYSIS_GROUP)}
          </List>
        </Collapse>
      </List>
    </Box>
  );
};

export default Menu;
