import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Case } from '../interfaces/Case';
import { callGetCaseByIdApi } from '../services/api';

interface CaseContextProps {
  caseId: string | null;
  setCaseId: (id: string | null) => void;
  currentCase: Case | null;
}

const CaseContext = createContext<CaseContextProps | undefined>(undefined);

export const CaseProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [caseId, setCaseId] = useState<string | null>(null);
  const [currentCase, setCurrentCase] = useState<Case | null>(null);

  useEffect(() => {
    const fetchCurrentCase = async () => {
      if (caseId) {
        try {
          const response = await callGetCaseByIdApi(caseId);
          setCurrentCase(response.data as Case);
        } catch (error) {
          console.error('Error fetching case:', error);
          setCurrentCase(null);
        }
      } else {
        setCurrentCase(null);
      }
    };

    fetchCurrentCase();
  }, [caseId]);

  return (
    <CaseContext.Provider value={{ caseId, setCaseId, currentCase }}>
      {children}
    </CaseContext.Provider>
  );
};

export const useCaseContext = () => {
  const context = useContext(CaseContext);
  if (!context) {
    throw new Error('useCaseContext must be used within a CaseProvider');
  }
  return context;
};
