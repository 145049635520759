import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useAuthContext } from '../../context/AuthProvider';

interface SettingsProps {
  settings: {
    language: string;
    state: string;
    mineType: string;
    commodity: string;
    llm: string;
    llmTemperature: string;
  };
  setSettings: (settings: {
    language: string;
    state: string;
    mineType: string;
    commodity: string;
    llm: string;
    llmTemperature: string;
  }) => void;
  isSettingsOpen: boolean;
  loading?: boolean;
  handleSettingsClick: () => void;
}

const Settings: React.FC<SettingsProps> = ({
  settings,
  setSettings,
  isSettingsOpen,
  handleSettingsClick,
  loading: disabled = false,
}) => {
  const { user } = useAuthContext(); // Get the user from the context

  // Function to generate temperature options based on LLM model
  const getTemperatureOptions = (llm: string): string[] => {
    if (llm === 'gpt-4o' || llm === 'gpt-4o-mini') {
      // Temperatures from 0.0 to 2.0 in increments of 0.1
      return Array.from({ length: 21 }, (_, i) => (i * 0.1).toFixed(1));
    } else if (llm === 'claude-opus' || llm === 'claude-sonnet') {
      // Temperatures from 0.0 to 1.0 in increments of 0.1
      return Array.from({ length: 11 }, (_, i) => (i * 0.1).toFixed(1));
    }
    // Default range if LLM model is unrecognized
    return [];
  };

  // Determine the current temperature options
  const temperatureOptions = getTemperatureOptions(settings.llm);

  // Determine the default temperature based on LLM model
  const getDefaultTemperature = (llm: string): string => {
    if (llm === 'gpt-4o' || llm === 'gpt-4o-mini') {
      return '1.0';
    } else if (llm === 'claude-opus' || llm === 'claude-sonnet') {
      return '0.7';
    }
    // Default value if LLM model is unrecognized
    return '';
  };

  // Effect to reset temperature when LLM model changes
  useEffect(() => {
    const currentTemperature = parseFloat(settings.llmTemperature);
    const maxTemperature =
      settings.llm === 'gpt-4o' || settings.llm === 'gpt-4o-mini' ? 2.0 : 1.0;
    if (currentTemperature > maxTemperature) {
      setSettings({
        ...settings,
        llmTemperature: getDefaultTemperature(settings.llm),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.llm]);

  return (
    <Box sx={{ padding: 2, paddingBottom: 0, width: 250 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 1,
          width: '100%',
        }}
      >
        <Button
          onClick={handleSettingsClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            textTransform: 'none',
            padding: 0,
            fontSize: '0.875rem',
            color: '#0074a6',
            '&:hover': {
              color: '#005174',
            },
            width: '100%',
          }}
        >
          <SettingsIcon sx={{ marginRight: 1 }} />
          <Typography variant="body1">Settings</Typography>
          {isSettingsOpen ? (
            <ExpandLessIcon sx={{ marginLeft: 'auto' }} />
          ) : (
            <ExpandMoreIcon sx={{ marginLeft: 'auto' }} />
          )}
        </Button>
      </Box>
      {isSettingsOpen && (
        <Box sx={{ marginBottom: 1, width: '100%' }}>
          {/* Language Select */}
          {/* <FormControl
            fullWidth
            variant="outlined"
            size="small"
            sx={{ marginBottom: 1, marginTop: 1 }}
          >
            <InputLabel htmlFor="language-select" sx={{ fontSize: '0.85rem' }}>
              Language
            </InputLabel>
            <Select
              id="language-select"
              value={settings.language}
              onChange={e =>
                setSettings({ ...settings, language: e.target.value as string })
              }
              label="Language"
              sx={{ fontSize: '0.85rem' }}
              MenuProps={{
                PaperProps: {
                  style: {
                    fontSize: '0.85rem',
                    width: 200,
                  },
                },
              }}
            >
              {[
                'Albanian',
                'Amharic',
                'Arabic',
                'Armenian',
                'Bengali',
                'Bosnian',
                'Bulgarian',
                'Burmese',
                'Catalan',
                'Chinese',
                'Croatian',
                'Czech',
                'Danish',
                'Dutch',
                'English',
                'Estonian',
                'Finnish',
                'French',
                'Georgian',
                'German',
                'Greek',
                'Gujarati',
                'Hindi',
                'Hungarian',
                'Icelandic',
                'Indonesian',
                'Italian',
                'Japanese',
                'Kannada',
                'Kazakh',
                'Korean',
                'Latvian',
                'Lithuanian',
                'Macedonian',
                'Malay',
                'Malayalam',
                'Marathi',
                'Mongolian',
                'Norwegian',
                'Persian',
                'Polish',
                'Portuguese',
                'Punjabi',
                'Romanian',
                'Russian',
                'Serbian',
                'Slovak',
                'Slovenian',
                'Somali',
                'Spanish',
                'Swahili',
                'Swedish',
                'Tagalog',
                'Tamil',
                'Telugu',
                'Thai',
                'Turkish',
                'Ukrainian',
                'Urdu',
                'Vietnamese',
              ].map(language => (
                <MenuItem
                  key={language}
                  value={language}
                  sx={{ fontSize: '0.85rem' }}
                >
                  {language}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          {/* State Select */}
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            sx={{ marginBottom: 1, marginTop: 1 }}
          >
            <InputLabel htmlFor="state-select" sx={{ fontSize: '0.85rem' }}>
              State
            </InputLabel>
            <Select
              id="state-select"
              value={settings.state}
              disabled={disabled}
              onChange={e =>
                setSettings({ ...settings, state: e.target.value as string })
              }
              label="State"
              sx={{ fontSize: '0.85rem' }}
              MenuProps={{
                PaperProps: {
                  style: {
                    fontSize: '0.85rem',
                    width: 200,
                  },
                },
              }}
            >
              <MenuItem value="Queensland" sx={{ fontSize: '0.85rem' }}>
                QLD
              </MenuItem>
              <MenuItem value="Northern Territory" sx={{ fontSize: '0.85rem' }}>
                NT
              </MenuItem>
              <MenuItem value="New South Wales" sx={{ fontSize: '0.85rem' }}>
                NSW
              </MenuItem>
              <MenuItem value="Victoria" sx={{ fontSize: '0.85rem' }}>
                VIC
              </MenuItem>
              <MenuItem value="South Australia" sx={{ fontSize: '0.85rem' }}>
                SA
              </MenuItem>
              <MenuItem value="Western Australia" sx={{ fontSize: '0.85rem' }}>
                WA
              </MenuItem>
              <MenuItem value="Tasmania" sx={{ fontSize: '0.85rem' }}>
                TAS
              </MenuItem>
            </Select>
          </FormControl>

          {/* Mine Type Select */}
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            sx={{ marginBottom: 1, marginTop: 1 }}
          >
            <InputLabel htmlFor="mine-type-select" sx={{ fontSize: '0.85rem' }}>
              Mine Type
            </InputLabel>
            <Select
              id="mine-type-select"
              value={settings.mineType}
              disabled={disabled}
              onChange={e =>
                setSettings({ ...settings, mineType: e.target.value as string })
              }
              label="Mine Type"
              sx={{ fontSize: '0.85rem' }}
              MenuProps={{
                PaperProps: {
                  style: {
                    fontSize: '0.85rem',
                    width: 200,
                  },
                },
              }}
            >
              <MenuItem value="open cut" sx={{ fontSize: '0.85rem' }}>
                Open cut
              </MenuItem>
              <MenuItem value="underground" sx={{ fontSize: '0.85rem' }}>
                Underground
              </MenuItem>
            </Select>
          </FormControl>

          {/* Commodity Select */}
          <FormControl
            fullWidth
            variant="outlined"
            size="small"
            sx={{ marginTop: 1 }}
          >
            <InputLabel htmlFor="commodity-select" sx={{ fontSize: '0.85rem' }}>
              Commodity
            </InputLabel>
            <Select
              id="commodity-select"
              value={settings.commodity}
              disabled={disabled}
              onChange={e =>
                setSettings({
                  ...settings,
                  commodity: e.target.value as string,
                })
              }
              label="Commodity"
              sx={{ fontSize: '0.85rem' }}
              MenuProps={{
                PaperProps: {
                  style: {
                    fontSize: '0.85rem',
                    width: 200,
                  },
                },
              }}
            >
              <MenuItem value="coal" sx={{ fontSize: '0.85rem' }}>
                Coal
              </MenuItem>
              <MenuItem value="metal" sx={{ fontSize: '0.85rem' }}>
                Metal
              </MenuItem>
              <MenuItem value="quarry" sx={{ fontSize: '0.85rem' }}>
                Quarry
              </MenuItem>
            </Select>
          </FormControl>

          {/* Conditionally render LLM model and temperature selects */}
          {user?.role === 'superuser' && (
            <>
              {/* LLM Select */}
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ marginTop: 2 }}
              >
                <InputLabel htmlFor="llm-select" sx={{ fontSize: '0.85rem' }}>
                  LLM model
                </InputLabel>
                <Select
                  id="llm-select"
                  value={settings.llm}
                  disabled={disabled}
                  onChange={e => {
                    const newLlm = e.target.value as string;
                    setSettings({
                      ...settings,
                      llm: newLlm,
                      // Reset temperature to default when LLM changes
                      llmTemperature: getDefaultTemperature(newLlm),
                    });
                  }}
                  label="LLM model"
                  sx={{ fontSize: '0.85rem' }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        fontSize: '0.85rem',
                        width: 200,
                      },
                    },
                  }}
                >
                  <MenuItem value="gpt-4o" sx={{ fontSize: '0.85rem' }}>
                    gpt-4o
                  </MenuItem>
                  <MenuItem value="gpt-4o-mini" sx={{ fontSize: '0.85rem' }}>
                    gpt-4o-mini
                  </MenuItem>
                  <MenuItem value="claude-opus" sx={{ fontSize: '0.85rem' }}>
                    claude-opus
                  </MenuItem>
                  <MenuItem value="claude-sonnet" sx={{ fontSize: '0.85rem' }}>
                    claude-sonnet
                  </MenuItem>
                </Select>
              </FormControl>

              {/* LLM Temperature Select */}
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ marginTop: 2 }}
              >
                <InputLabel
                  htmlFor="llm-temperature-select"
                  sx={{ fontSize: '0.85rem' }}
                >
                  LLM temperature
                </InputLabel>
                <Select
                  id="llm-temperature-select"
                  value={settings.llmTemperature}
                  disabled={disabled || !settings.llm}
                  onChange={e =>
                    setSettings({
                      ...settings,
                      llmTemperature: e.target.value as string,
                    })
                  }
                  label="LLM temperature"
                  sx={{ fontSize: '0.85rem' }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        fontSize: '0.85rem',
                        width: 200,
                      },
                    },
                  }}
                >
                  {temperatureOptions.map(temp => (
                    <MenuItem
                      key={temp}
                      value={temp}
                      sx={{ fontSize: '0.85rem' }}
                    >
                      {temp}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Settings;
