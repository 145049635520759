import React, { createContext, ReactNode, useContext, useState } from 'react';

interface CompletionResultContextType {
  completionResults: { [key: string]: string };
  setCompletionResult: (key: string, result: string) => void;
  resetCompletionResults: () => void;
}

const CompletionResultContext = createContext<
  CompletionResultContextType | undefined
>(undefined);

const CompletionResultProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [completionResults, setCompletionResults] = useState<{
    [key: string]: string;
  }>({});

  const setCompletionResult = (key: string, result: string) => {
    setCompletionResults(prevResults => ({ ...prevResults, [key]: result }));
  };

  // Function to clear all completion results
  const resetCompletionResults = () => {
    setCompletionResults({}); // Clear the entire object
  };

  return (
    <CompletionResultContext.Provider
      value={{
        completionResults,
        setCompletionResult,
        resetCompletionResults,
      }}
    >
      {children}
    </CompletionResultContext.Provider>
  );
};

const useCompletionResultContext = () => {
  const context = useContext(CompletionResultContext);
  if (!context) {
    throw new Error(
      'useCompletionResultContext must be used within a CompletionResultProvider'
    );
  }
  return context;
};

export { CompletionResultProvider, useCompletionResultContext };
