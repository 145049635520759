import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FileToUpload } from '../interfaces/FileToUpload';

interface FileContextProps {
  files: FileToUpload[];
  setFiles: React.Dispatch<React.SetStateAction<FileToUpload[]>>;
  totalSize: number;
}

const FileContext = createContext<FileContextProps>({
  files: [],
  setFiles: () => {},
  totalSize: 0,
});

interface FileProviderProps {
  children: ReactNode;
}

export const FileProvider: React.FC<FileProviderProps> = ({ children }) => {
  const [files, setFiles] = useState<FileToUpload[]>([]);
  const [totalSize, setTotalSize] = useState(0);

  // Calculate total size whenever files change
  useEffect(() => {
    const totalSizeInBytes = files.reduce(
      (total, file) => total + file.sizeMb,
      0
    );
    setTotalSize(totalSizeInBytes);
  }, [files]);

  return (
    <FileContext.Provider value={{ files, setFiles, totalSize }}>
      {children}
    </FileContext.Provider>
  );
};

export const useFileContext = () => {
  const context = useContext(FileContext);
  if (!context) {
    throw new Error('useFileContext must be used within a FileProvider');
  }
  return context;
};
