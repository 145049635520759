import axios from 'axios';

const apiBaseURL =
  process.env.REACT_APP_ICAM_API_URL ||
  `${window.location.protocol}//${window.location.host}/api`;

// Create an Axios instance
const api = axios.create({
  baseURL: apiBaseURL,
  withCredentials: true, // To send cookies along with requests
});

// Set up the token in the header for authenticated requests
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

export const login = async (email: string, password: string) => {
  return api.post('/auth/login', { email, password });
};

export const getProtectedData = async () => {
  return api.get('/auth/protected');
};

export const callQueryDocumentsApi = async (
  apiEndpoint: string,
  filesFromFileSystem: File[],
  existingFilesFromDb: { name: string; isRemoved: boolean }[],
  superuserPrompt?: string,
  caseId?: string | null
) => {
  const formData = new FormData();
  filesFromFileSystem.forEach(file => {
    formData.append('files', file);
  });

  if (existingFilesFromDb?.length > 0) {
    formData.append('existingFilesFromDb', JSON.stringify(existingFilesFromDb));
  }

  if (superuserPrompt) {
    formData.append('superuserPrompt', superuserPrompt);
  }
  if (caseId) {
    formData.append('caseId', caseId);
  }

  const response = await api.post(`${apiBaseURL}/${apiEndpoint}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    withCredentials: true,
  });

  return response;
};

export const callToolsApi = async (toolName: string) => {
  const endpoint = `${apiBaseURL}/tools/${toolName}`;

  const response = await api.get(endpoint, {
    withCredentials: true,
  });

  return response;
};

export const callCreateCaseApi = async (title: string, visibility: string) => {
  const requestBody = {
    title,
    visibility,
  };

  const response = await api.post(`${apiBaseURL}/cases`, requestBody, {
    withCredentials: true,
  });

  return response;
};

export const callGetCasesApi = async () => {
  const response = await api.get(`${apiBaseURL}/cases`, {
    withCredentials: true,
  });

  return response;
};

export const callGetFeatureFlagsApi = async (name: string) => {
  const response = await api.get(`${apiBaseURL}/feature-flags/${name}`,{
    withCredentials: true,
  });

  return response;
};

export const callGetCaseByIdApi = async (id: string) => {
  const response = await api.get(`${apiBaseURL}/cases/${id}`, {
    withCredentials: true,
  });

  return response;
};

export const callGetFileInfoByCaseApi = async (caseId: string) => {
  const endpoint = `${apiBaseURL}/fileInfo/${caseId}`;

  const response = await api.get(endpoint, {
    withCredentials: true,
  });

  return response;
};

export const callToolCompletionByCaseApi = async (
  caseId: string,
  toolId: string
) => {
  const endpoint = `${apiBaseURL}/toolCompletions/${caseId}/${toolId}`;

  const response = await api.get(endpoint, {
    withCredentials: true,
  });

  return response;
};

export default api;
