import { ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCaseContext } from './context/CaseProvider';

type CaseRouteHandlerProps = {
  children: ReactNode;
};

const CaseRouteHandler = ({ children }: CaseRouteHandlerProps) => {
  const { caseId: caseIdParam } = useParams<{ caseId?: string }>();
  const { setCaseId } = useCaseContext();

  useEffect(() => {
    setCaseId(caseIdParam || null);
  }, [caseIdParam, setCaseId]);

  return <>{children}</>;
};

export default CaseRouteHandler;
