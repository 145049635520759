import { Box, Typography } from '@mui/material';
import React from 'react';
import { useCaseContext } from '../../context/CaseProvider';

interface CaseLayoutProps {
  children: React.ReactNode;
}

const MenuItemLayout: React.FC<CaseLayoutProps> = ({ children }) => {
  const { caseId, currentCase } = useCaseContext();

  return (
    <Box sx={{ padding: '32px' }}>
      <Box sx={{ color: 'darkgrey' }}>
        <Typography sx={{ fontSize: '1.1em' }}>
          {caseId
            ? `INCIDENT: ${currentCase?.title || 'Loading...'}`
            : 'EXPLORE MODE'}
        </Typography>
        <hr />
      </Box>
      {children}
    </Box>
  );
};

export default MenuItemLayout;
