import {
  ArrowBack as BackIcon,
  RemoveCircleOutline as RemoveIcon,
  UploadOutlined as UploadIcon,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCaseContext } from '../../context/CaseProvider';
import { useCompletionResultContext } from '../../context/CompletionResultProvider';
import { useFileContext } from '../../context/FileProvider';
import { useLoadingContext } from '../../context/LoadingProvider';
import { useSettingsContext } from '../../context/SettingsProvider';
import { FileToUpload } from '../../interfaces/FileToUpload';
import { callGetFileInfoByCaseApi } from '../../services/api';
import LogoutButton from '../common/LogoutButton';
import Settings from './Settings';

// Utility function to format bytes into a readable string
const formatBytes = (sizeInMb: number, decimals = 2) => {
  return `${sizeInMb.toFixed(decimals)} MB`;
};

const UploadPanel: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { files, setFiles } = useFileContext();
  const { settings, setSettings } = useSettingsContext();
  // Settings is expanded by default in desktop mode. In mobile there isn't enough space so shrinked.
  const [isSettingsExpanded, setIsSettingsExpanded] = useState(
    isMobile ? false : true
  );
  const [errorMessage, setErrorMessage] = useState('');
  const { loading } = useLoadingContext();

  // State to keep track of total file size
  const [totalSize, setTotalSize] = useState(0);
  const [fileListChanged, setFileListChanged] = useState(false);

  // Use a ref to keep track of file names for duplicate checking
  const fileNamesRef = useRef<Set<string>>(
    new Set(files.filter(file => !file.isRemoved).map(file => file.name))
  );

  // Assuming you have access to caseId from context or props
  const { caseId } = useCaseContext();

  // State for loading existing files
  const [isLoadingExistingFiles, setIsLoadingExistingFiles] =
    useState<boolean>(true);

  useEffect(() => {
    const fetchExistingFiles = async () => {
      if (!caseId) {
        setIsLoadingExistingFiles(false);
        return;
      }
      try {
        const response = await callGetFileInfoByCaseApi(caseId);
        const existingFiles: FileToUpload[] = response.data.map(
          (fileInfo: any) => ({
            id: fileInfo.id,
            name: fileInfo.name,
            type: fileInfo.fileType,
            sizeMb: fileInfo.sizeMb,
            isExisting: true,
            isRemoved: false, // Initialize isRemoved as false
          })
        );
        setFiles(existingFiles);
        existingFiles.forEach(file => fileNamesRef.current.add(file.name));
      } catch (error) {
        console.error('Error fetching existing files:', error);
        setErrorMessage('Failed to load existing files.');
      } finally {
        setIsLoadingExistingFiles(false);
      }
    };

    fetchExistingFiles();
  }, [caseId, setFiles]);

  useEffect(() => {
    // Calculate the total size excluding removed files
    const totalSizeInMb = files
      .filter(file => !file.isRemoved)
      .reduce((total, file) => total + file.sizeMb, 0);
    setTotalSize(totalSizeInMb);
  }, [files]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (loading) return;

    if (e.target.files) {
      setFileListChanged(true);

      const selectedFiles = Array.from(e.target.files);

      let hasDuplicate = false;

      setFiles(prevFiles => {
        const updatedFiles = [...prevFiles];

        for (const file of selectedFiles) {
          const existingFileIndex = updatedFiles.findIndex(
            f => f.name === file.name
          );

          if (existingFileIndex !== -1) {
            const existingFile = updatedFiles[existingFileIndex];

            if (existingFile.isRemoved) {
              // File exists and was removed. Restore it.
              updatedFiles[existingFileIndex] = {
                ...existingFile,
                isRemoved: false,
                actualFile: file,
                sizeMb: file.size / (1024 * 1024),
                type: file.type,
              };
              // Add back to fileNamesRef
              fileNamesRef.current.add(file.name);
            } else {
              // File exists and is not removed. It's a duplicate.
              hasDuplicate = true;
              continue;
            }
          } else {
            // New file, add it.
            updatedFiles.push({
              id: `${Date.now()}-${file.name}`, // Generate a unique ID
              name: file.name,
              actualFile: file,
              sizeMb: file.size / (1024 * 1024),
              isExisting: false,
              type: file.type,
              isRemoved: false, // Initialize isRemoved as false
            });
            fileNamesRef.current.add(file.name);
          }
        }

        return updatedFiles;
      });

      if (hasDuplicate) {
        setErrorMessage(
          'Some selected file(s) already exist. Please rename if they have different content.'
        );
      } else {
        setErrorMessage('');
      }

      // Reset the input value to allow re-selection of the same files
      e.target.value = '';
    }
  };

  const handleRemoveFile = (fileId: string) => {
    setFiles(prevFiles => {
      const updatedFiles = prevFiles.map(file => {
        if (file.id === fileId) {
          // Remove file name from fileNamesRef
          fileNamesRef.current.delete(file.name);
          return { ...file, isRemoved: true };
        }
        return file;
      });

      return updatedFiles;
    });
    setErrorMessage('');
  };

  const handleClearFiles = () => {
    setFiles(prevFiles =>
      prevFiles.map(file => {
        fileNamesRef.current.delete(file.name);
        return { ...file, isRemoved: true };
      })
    );
    setErrorMessage('');
  };

  const supportedFileTypes: string = [
    '.pdf',
    '.jpg',
    '.jpeg',
    '.png',
    '.tiff',
    '.docx',
    '.txt',
    '.wav',
    '.ogg',
    '.mp3',
    '.flac',
    '.amr',
    '.mp4',
    '.avi',
    '.mkv',
    '.mov',
    '.flv',
    '.wmv',
    '.webm',
    '.mpeg',
    '.mpg',
    '.3gp',
    '.ogv',
  ].join(', ');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: isMobile ? 'auto' : '100%', // Adjust height based on device
        padding: isMobile ? theme.spacing(2) : 0, // Add padding on mobile
      }}
    >
      <Box
        sx={{
          paddingBottom: isMobile ? theme.spacing(2) : 0,
          paddingTop: !isMobile ? theme.spacing(2) : 0,
        }}
      >
        <Settings
          settings={settings}
          setSettings={setSettings}
          isSettingsOpen={isSettingsExpanded}
          handleSettingsClick={() => setIsSettingsExpanded(!isSettingsExpanded)}
          loading={loading}
        />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          padding: isMobile ? 0 : 2, // Adjust padding
        }}
      >
        <input
          id="fileInput"
          style={{ display: 'none' }}
          type="file"
          onChange={handleFileChange}
          accept={supportedFileTypes}
          multiple
          data-testid="file-input"
          disabled={loading}
        />
        <InputLabel
          htmlFor={!loading ? 'fileInput' : undefined}
          sx={{ width: '100%' }}
        >
          <Button
            sx={{
              backgroundColor: '#008009',
              '&:hover': {
                backgroundColor: '#36A137',
              },
              marginTop: 0,
              width: '100%',
            }}
            variant="contained"
            component="span"
            startIcon={<UploadIcon />}
            data-testid="select-files-button"
            disabled={loading}
          >
            Select Files
          </Button>
        </InputLabel>
        {errorMessage && !loading && (
          <Alert
            severity="error"
            sx={{
              mt: 1,
              mb: 0,
              p: '4px 8px',
              fontSize: '0.75rem',
            }}
          >
            {errorMessage}
          </Alert>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 2,
            paddingRight: isMobile ? '16px' : 0, // Add padding on mobile
          }}
        >
          {/* Loaded from previous session */}
          {caseId && files?.length > 0 && !fileListChanged && (
            <Box sx={{ color: 'darkgrey', marginTop: '8px' }}>
              <Typography sx={{ fontSize: '0.9em' }}>
                Loaded from previous session
              </Typography>
              <hr />
            </Box>
          )}

          {/* Total Size of Files */}
          <Typography
            variant="body2"
            sx={{
              color: '#7EA8CB',
              fontWeight: 400,
              fontSize: '0.85rem',
              marginBottom: '16px',
            }}
          >
            Total File Size: {formatBytes(totalSize)}
          </Typography>

          {/* File List */}
          {isLoadingExistingFiles ? (
            <CircularProgress sx={{ marginTop: '16px' }} />
          ) : (
            files
              .filter(file => !file.isRemoved)
              .map(file => (
                <Box
                  key={file.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '8px',
                    position: 'relative',
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#5c5c5c',
                      flexGrow: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      marginRight: '8px',
                    }}
                  >
                    {file.name}
                  </Typography>
                  <Tooltip title="Remove">
                    <IconButton
                      edge="end"
                      aria-label="remove"
                      onClick={() => handleRemoveFile(file.id)}
                      disabled={loading}
                      sx={{
                        flexShrink: 0,
                      }}
                    >
                      <RemoveIcon
                        sx={{ fontSize: '1.2rem', color: '#b30000' }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              ))
          )}
          {/* Clear Link */}
          {files.filter(file => !file.isRemoved).length >= 2 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2, // Margin top to separate from the last file
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: '#4682B4',
                  cursor: 'pointer',
                  fontSize: '0.8rem',
                  '&:hover': {
                    color: '#5a9bd8',
                  },
                }}
                onClick={() => {
                  if (!loading) handleClearFiles();
                }}
                aria-disabled={loading}
              >
                Clear
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {/* Logout button at the bottom right */}
      {!isMobile && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 1,
          }}
        >
          {/* TODO Cem: use feature flag to decide this, not the case id */}
          {/* {caseId && <BackButton />} */}
          <BackButton />
          <LogoutButton />
        </Box>
      )}
    </Box>
  );
};

const BackButton: React.FC = () => {
  const navigate = useNavigate(); // Hook for navigation
  const { setFiles } = useFileContext(); // Access the function to clear files
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { resetCompletionResults } = useCompletionResultContext();
  const openMenu = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigateToCases = () => {
    setFiles([]); // Clear selected files
    resetCompletionResults();
    navigate('/cases');
    handleMenuClose();
  };

  return (
    <>
      <IconButton
        onClick={handleMenuOpen}
        aria-label="back"
        sx={{ color: 'grey' }}
      >
        <BackIcon sx={{ fontSize: '1.5rem' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleNavigateToCases}>
          <Typography variant="body1" sx={{ color: 'grey' }}>
            Incidents
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UploadPanel;
