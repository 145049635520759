import React, { createContext, ReactNode, useContext, useState } from 'react';

interface Settings {
  language: string;
  state: string;
  mineType: string;
  commodity: string;
  llm: string;
  llmTemperature: string;
}

interface SettingsContextProps {
  settings: Settings;
  setSettings: React.Dispatch<React.SetStateAction<Settings>>;
}

// Create the context with default values
const SettingsContext = createContext<SettingsContextProps | undefined>(
  undefined
);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};

// Define the provider component
export const SettingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [settings, setSettings] = useState<Settings>({
    language: 'English',
    state: 'New South Wales',
    mineType: 'underground',
    commodity: 'metal',
    llm: '',
    llmTemperature: '',
  });

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};
