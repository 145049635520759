import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FileToUpload } from '../../../interfaces/FileToUpload';

// Utility function to get the current time + estimated minutes
const getEstimatedFinishTime = (additionalMinutes: number) => {
  const finishTime = new Date();
  finishTime.setMinutes(finishTime.getMinutes() + additionalMinutes);
  return finishTime.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
};

// Component to display estimated completion time message
export const CompletionTimeMessage: React.FC<{
  totalSize: number;
  files: FileToUpload[];
}> = ({ totalSize, files }) => {
  const [estimatedMessage, setEstimatedMessage] = useState<string>('');

  useEffect(() => {
    // Calculate audio/video total size separately
    const audioVideoFileSize = files
      .filter(
        file => file.type.includes('audio') || file.type.includes('video')
      )
      .reduce((total, file) => total + file.sizeMb, 0);

    // Determine the estimated completion time message based on conditions
    let message = '';
    let estimatedMinutes = 0;

    if (audioVideoFileSize > 60 * 1024 * 1024) {
      message = `An audio/video file is more than 60MB. First run may take a while, estimated completion time: `;
      estimatedMinutes = 6;
    } else if (totalSize > 100 * 1024 * 1024) {
      message = `Total file size is more than 100MB. First run may take a while, estimated completion time: `;
      estimatedMinutes = 6;
    } else if (totalSize > 60 * 1024 * 1024) {
      message = `Total file size is more than 60MB. First run may take a while, estimated completion time: `;
      estimatedMinutes = 3;
    } else if (audioVideoFileSize > 30 * 1024 * 1024) {
      message = `An audio/video file is more than 30MB. First run may take a while, estimated completion time: `;
      estimatedMinutes = 3;
    } else if (totalSize > 40 * 1024 * 1024) {
      message = `Total file size is more than 40MB. First run may take a while, estimated completion time: `;
      estimatedMinutes = 2;
    } else {
      message =
        'Processing your request... This may take up to 3 minutes depending on the input.';
    }

    // Update the state with the estimated message
    if (estimatedMinutes > 0) {
      const estimatedFinishTime = getEstimatedFinishTime(estimatedMinutes);
      setEstimatedMessage(`${message} ${estimatedFinishTime}`);
    } else {
      setEstimatedMessage(`${message}`);
    }
  }, [totalSize, files]);

  return estimatedMessage ? (
    <Typography
      sx={{ fontSize: '12px', color: '#666666', mt: 2 }}
      data-testid="loading-message"
      align="center"
    >
      {estimatedMessage}
    </Typography>
  ) : null;
};
